<template>
  <div class="onboarding-form">
    <div class="pb-5 pt-lg-0 pt-3">
      <h3 class="font-weight-bolder text-dark font-size-h4 font-size-h1-lg">
        WELCOME TO PILOT.
      </h3>
      <h4 class="my-5">
        Dear {{ currentUser.firstname }} {{ currentUser.lastname }},
      </h4>
      <h4>
        Welcome to Pilot. The Most Advanced Cloud ERP For Health, Safety &
        Environment.
      </h4>
      <h4>
        Before we get started kindly take a moment to create your organisation
        on Pilot.
      </h4>
    </div>

    <div class="pb-lg-0 pb-5 mt-5">
      <router-link to="/onboarding/company" v-slot="{ navigate }">
        <button
          type="button"
          class="btn btn-light-primary font-weight-bolder font-size-h5 px-15 py-4 my-3 mr-3"
          @click="navigate"
        >
          Create My Organisation<i class="fas fa-arrow-right ml-2"></i>
        </button>
      </router-link>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      isAutomatic: true,
      loading: false
    };
  },
  components: {},
  computed: {
    ...mapState({
      errors: state => state.auth.errors
    }),
    ...mapGetters(["currentUser", "currentToken"])
  },
  mounted() {},
  methods: {}
};
</script>
